import React, { createRef, FunctionComponent, useState } from 'react'
import Layout from '../components/LayoutBlog'
import { Post, Tag } from '../utils/models'
import { Container } from '../components/Common'
import { Card } from '../components/Card'
import { Reserve } from '../components/Reserve'
import styled from 'styled-components'
import { Toc } from '../components/Toc'
import { GatsbyImage } from 'gatsby-plugin-image'
import { ReadingProgress } from '../components/ReadingProgress'
import { graphql, Link } from 'gatsby'
import slugify from 'slugify'
import { Bio } from '../components/Bio'
import { Comments } from '../components/Comments'
import { SEO } from '../components/SEO'
import { FaAlignJustify, FaTimes } from 'react-icons/fa'
import formatDate from '../utils/formatDate'

interface PostTemplateProps {
  data: {
    primaryTag: Tag | null
    post: Post
    lastPostsRaw: {
      edges: [Post]
    }
  }
  location: Location
}

const PostContainer = styled(Container)`
  display: flex;
  justify-content: center;
  padding: 0 !important;
`

const LeftSidebar = styled.div<{ show?: boolean }>`
  transition: opacity 0.5s;

  @media (max-width: ${(props) => props.theme.breakpoints.xl}) {
    position: fixed;
    opacity: ${(props) => (props.show ? 1 : 0)};
    background-color: #fff;
    width: 100% !important;
    max-width: 100%;
    padding: 0 20px;
    margin-top: -5px;
    height: calc(100vh - 70px);
  }
`

const PostContent = styled.div`
  margin-top: -5px;
  border-right: 1px #e5eff5 solid;
  border-left: 1px #e5eff5 solid;
  background-color: #fff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.03), 0 3px 46px rgba(0, 0, 0, 0.1);
  z-index: 10;
  width: 1035px;
  max-width: 100%;

  li > a,
  p > a {
    color: ${(props) => props.theme.layout.linkColor};
    border-bottom: 2px ${(props) => props.theme.layout.linkColor} solid;
  }

  pre {
    margin: 30px 0;
  }

  blockquote {
    border-left: 4px ${(props) => props.theme.layout.primaryColor} solid;
    background-color: ${(props) => props.theme.layout.backgroundColor};
    margin: 30px 0;
    padding: 5px 20px;
    border-radius: 0.3em;
  }

  h3::before,
  h4::before,
  h5::before,
  h6::before {
    display: block;
    content: ' ';
    height: 90px;
    margin-top: -90px;
    visibility: hidden;
  }

  h2 {
    border-top: 1px solid #ececec;
    margin-top: 44px;
    padding-top: 40px;
    line-height: 1.2;
  }

  code[class*='language-text'] {
    padding: 5px;
  }

  p > img {
    max-width: 100%;
    border-radius: 0.3em;
    margin: 30px 0;
  }

  hr {
    border-top: 1px solid #ececec;
    border-bottom: 0;
    margin-top: 44px;
    margin-bottom: 40px;
  }

  .gatsby-resp-image-link {
    margin: 30px 0;
    max-width: 100%;

    .gatsby-resp-image-image {
      border-radius: 0.3em;
    }
  }
`

const TocWrapper = styled.div`
  position: sticky;
  top: 70px;
  padding: 40px 30px;
`

const PostHeader = styled.header`
  padding: 40px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    padding: 20px;
  }
`

const FeaturedImage = styled(GatsbyImage)`
  border-radius: 0;

  @media (max-width: ${(props) => props.theme.breakpoints.xl}) {
    margin-left: -1px;
    margin-right: -1px;
  }
`

const StyledPost = styled.section`
  padding: 40px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    padding: 20px;
  }
`

const PostMeta = styled.section`
  display: flex;
  justify-content: space-between;
  opacity: 0.8;
  font-size: 0.9em;
`

const PostTitle = styled.h1`
  margin: 0;
  padding: 0;
`

const PostFooter = styled.footer`
  background-color: #fafafa;
  font-size: 0.8em;
  color: #666;
  padding: 40px;
  line-height: 1em;

  p {
    margin: 5px 0;
  }
`

const FooterTagLink = styled(Link)`
  color: #000 !important;
  text-decoration: none;
  border-bottom: 0 !important;
`

const PostAddition = styled.section`
  background-color: #fff;
  border-top: 1px #e5eff5 solid;
  border-bottom: 1px #e5eff5 solid;
  z-index: 700;
  position: relative;
  padding: 40px;
`

const PostAdditionContent = styled(Container)`
  display: flex;
  justify-content: space-between;
`

const BioWrapper = styled.div`
  width: 50%;
  margin: auto;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 100%;
  }
`

const ToggleTocButton = styled.button`
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  right: 20px;
  bottom: 20px;
  border-radius: 100%;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.03), 0 3px 46px rgba(0, 0, 0, 0.1);
  border: 0;
  z-index: 5000;
  width: 50px;
  height: 50px;
  background-color: #20232a;
  color: #fff;
  outline: none;

  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    display: none;
  }
`

const RightSidebar = styled.div<{ show?: boolean }>`
  transition: opacity 0.5s;

  @media (max-width: ${(props) => props.theme.breakpoints.xl}) {
    position: fixed;
    opacity: ${(props) => (props.show ? 1 : 0)};
    background-color: #fff;
    width: 100% !important;
    max-width: 100%;
    padding: 0 20px;
    margin-top: -5px;
    height: calc(100vh - 70px);
  }
`
const RightSidebarWrapper = styled.div`
  position: sticky;
  top: 70px;
  padding: 20px 30px;
  max-width: 450px;
`

const RightSidebarCard = styled(Card)`
  margin-bottom: 15px;
`

const RecentPosts = styled.p`
  font-weight: bold;
  margin-bottom: 10px;
`

const PostTemplate: FunctionComponent<PostTemplateProps> = ({
  data: { post, primaryTag, lastPostsRaw },
  location,
}) => {
  const [showToc, setShowToc] = useState<boolean>(false)
  const readingProgressRef = createRef<HTMLElement>()
  const toggleToc = () => setShowToc(!showToc)
  const lastPosts = lastPostsRaw.edges.map((raw) => raw.node)

  return (
    <Layout bigHeader={false}>
      <SEO
        location={location}
        title={post.frontmatter.title}
        publishedAt={post.frontmatter.created}
        updatedAt={post.frontmatter.updated}
        tags={post.frontmatter.tags}
        description={post.frontmatter.excerpt}
        image={
          post.frontmatter.featuredImage
            ? post.frontmatter.featuredImage.childImageSharp.gatsbyImageData.src
            : null
        }
      />
      <ReadingProgress
        target={readingProgressRef}
        color={primaryTag ? primaryTag.color : undefined}
      />
      <PostContainer>
        {post.headings.find((h) => h.depth > 1) && (
          <>
            <LeftSidebar show={showToc}>
              <TocWrapper>
                <Toc onClick={toggleToc} />
              </TocWrapper>
            </LeftSidebar>
            <ToggleTocButton
              role={`button`}
              aria-label={`Toggle table of contents`}
              onClick={toggleToc}
            >
              {showToc ? <FaTimes /> : <FaAlignJustify />}
            </ToggleTocButton>
          </>
        )}
        <PostContent>
          <article className={`post`} ref={readingProgressRef}>
            <PostHeader>
              <PostMeta>
                {post.frontmatter.tags.length > 0 && (
                  <Link
                    to={`/tag/${slugify(post.frontmatter.tags[0], {
                      lower: true,
                    })}`}
                  >
                    {post.frontmatter.tags[0]}
                  </Link>
                )}
                <time dateTime={post.frontmatter.created}>
                  {formatDate(post.frontmatter.createdPretty)}
                </time>
              </PostMeta>
              <PostTitle>{post.frontmatter.title}</PostTitle>
            </PostHeader>
            {post.frontmatter.featuredImage && (
              <FeaturedImage
                image={
                  post.frontmatter.featuredImage.childImageSharp.gatsbyImageData
                }
                alt="Imagem principal"
              />
            )}
            <StyledPost
              dangerouslySetInnerHTML={{ __html: post.html }}
              className={`post`}
            />
            <Reserve />
            <PostFooter>
              <p>
                Publicado em &nbsp;
                {post.frontmatter.tags.map((tag, index) => (
                  <span key={index}>
                    <FooterTagLink to={`/tag/${slugify(tag, { lower: true })}`}>
                      {tag}
                    </FooterTagLink>
                    {post.frontmatter.tags.length > index + 1 && <>, </>}
                  </span>
                ))}
                &nbsp;em{' '}
                <time dateTime={post.frontmatter.created}>
                  {formatDate(post.frontmatter.createdPretty)}
                </time>
                .
              </p>
              {post.frontmatter.updated !== post.frontmatter.created && (
                <p>
                  Última atualização em{' '}
                  <time dateTime={post.frontmatter.updated}>
                    {formatDate(post.frontmatter.updatedPretty)}
                  </time>
                  .
                </p>
              )}
            </PostFooter>
          </article>
        </PostContent>
        <RightSidebar>
          <RightSidebarWrapper>
            <RecentPosts>Posts recentes</RecentPosts>
            {lastPosts &&
              lastPosts
                .filter(
                  (lastPost) =>
                    lastPost.frontmatter.title !== post.frontmatter.title,
                )
                .map((lastPost) => (
                  <RightSidebarCard
                    title={lastPost.frontmatter.title}
                    featuredImage={
                      lastPost.frontmatter.featuredImage &&
                      lastPost.frontmatter.featuredImage.childImageSharp
                        .gatsbyImageData
                    }
                    path={lastPost.frontmatter.path}
                    key={lastPost.frontmatter.path}
                    compact={true}
                    small={true}
                    meta={{
                      time: lastPost.frontmatter.created,
                      timePretty: formatDate(
                        lastPost.frontmatter.createdPretty,
                      ),
                      tag:
                        lastPost.frontmatter.tags &&
                        lastPost.frontmatter.tags.length > 0
                          ? lastPost.frontmatter.tags[0]
                          : null,
                    }}
                  />
                ))}
          </RightSidebarWrapper>
        </RightSidebar>
      </PostContainer>
      <PostAddition>
        <PostAdditionContent>
          <BioWrapper>
            <Bio showName={true} />
          </BioWrapper>
        </PostAdditionContent>
      </PostAddition>
      <Comments />
    </Layout>
  )
}

export default PostTemplate

export const query = graphql`
  query Post($postId: String!) {
    post: markdownRemark(id: { eq: $postId }) {
      headings {
        depth
      }
      frontmatter {
        title
        path
        tags
        excerpt
        created
        createdPretty: created(formatString: "DD MMMM, YYYY")
        updated
        updatedPretty: created(formatString: "DD MMMM, YYYY")
        featuredImage {
          childImageSharp {
            gatsbyImageData(quality: 75, layout: FULL_WIDTH)
          }
        }
      }
      html
    }
    lastPostsRaw: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(/posts/)/" } }
      sort: { fields: frontmatter___created, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            path
            tags
            created
            createdPretty: created(formatString: "DD MMMM, YYYY")
            excerpt
            featuredImage {
              childImageSharp {
                gatsbyImageData(quality: 75, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`
