import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import { Button } from './Button'
import { Input } from './Input'
import { Separator } from './Separator'
import { submitReservation } from '../utils/submitReservation'

const Wrapper = styled.section`
  display: flex;
  line-height: 20px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 25px;
  background-color: #fff;
  box-shadow: 0 1px 1px #e6e6e6, 0 2px 4px #e6e6e6;
  border-top: 5px solid ${(props) => props.theme.colors.primary.yellow};
`

const Title = styled.p`
  font-size: 16px;
  font-weight: bold;
  text-align: center;
`

const Subtitle = styled.p`
  font-size: 12px;
  font-weight: 400;
  text-align: center;
`

export const Reserve: FunctionComponent = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')

  return (
    <Wrapper>
      <Title>Mais tempo, mais vendas, menos burocracia.</Title>
      <Separator size={8} />
      <Subtitle>
        Descubra como a Vanna pode ajudar a levar seu negócio para o próximo
        nível!
      </Subtitle>
      <Separator size={12} />
      <Input
        placeholder="Nome completo"
        required
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setName(event.target.value)
        }}
      />
      <Separator size={8} />
      <Input
        placeholder="Email"
        type="email"
        required
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setEmail(event.target.value)
        }}
      />
      <Separator size={8} />
      <Button onClick={() => submitReservation({ name, email })}>
        Cadastre-se
      </Button>
      <Separator size={10} />
    </Wrapper>
  )
}
